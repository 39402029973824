<template>
  <el-container>
    <Header pageName="套餐管理"></Header>
    <el-main>
      <el-tabs v-model="activeName">
        <el-tab-pane label="套餐列表" name="1">
          <el-button type="primary" size="small" @click="showAddMeal">添加套餐</el-button>
          <el-form class="el-form-search" label-width="90px">
            <el-form-item label="套餐名称：">
              <el-input v-model="name" placeholder="请输入套餐名称" size="small"></el-input>
            </el-form-item>
            <el-form-item label="所属行业：">
              <el-select v-model="case_id" placeholder="请选择" size="small">
                <el-option v-for="(item, index) in caseList" :key="index" :label="item.name" :value="item.id"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label=" " label-width="20px">
              <el-button type="primary" size="small" @click="searchMeal">搜索</el-button>
              <el-button plain size="small" @click="cancelSearchMeal">清空搜索条件</el-button>
            </el-form-item>
          </el-form>
          <el-table :data="mealList" :header-cell-style="{ 'background-color': '#F8F9FA' }" row-key="id">
            <template slot="empty">
              <No />
            </template>
            <el-table-column prop="name" label="套餐名称" align="center"></el-table-column>
            <el-table-column prop="case_name" label="所属行业" align="center"></el-table-column>
            <el-table-column prop="price" label="套餐价格 元/年" align="center"></el-table-column>
            <el-table-column prop="discount" label="优惠价格 元/年" align="center"></el-table-column>
            <el-table-column prop="maxaccount" label="最大开启账号数量" align="center"></el-table-column>
            <el-table-column prop="maxsubaccount" label="最大开启客服数量" align="center"></el-table-column>
            <el-table-column prop="max_cashier_subaccount" label="最大开启收银台客服数量" width="200" align="center"></el-table-column>
            <el-table-column prop="update_time" label="更新日期" align="center">
              <template v-slot="{ row }">{{ getDateformat(row.update_time) }}</template>
            </el-table-column>
            <el-table-column prop="money" label="操作" align="center">
              <template v-slot="{ row }">
                <el-button @click="editMeal(row)" size="small" type="text">编辑</el-button>
                <el-button @click="delMeal(row)" size="small" type="text">删除</el-button>
              </template>
            </el-table-column>
          </el-table>
          <Paging :total="total_number" :page="page" :pageNum="rows" @updatePageNum="updateData"></Paging>
          <el-dialog title="添加/编辑套餐" :visible.sync="is_showAddMeal" width="700px">
            <el-form ref="form" :model="addMealForm" :rules="rules" label-width="160px">
              <el-form-item label="套餐名称：" prop="name">
                <el-input v-model="addMealForm.name"></el-input>
              </el-form-item>
              <el-form-item label="所属行业：" prop="case_id">
                <el-select v-model="addMealForm.case_id" placeholder="请选择">
                  <el-option v-for="(item, index) in caseList" :key="index" :label="item.name" :value="item.id"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="套餐价格：" prop="price">
                <el-input v-model="addMealForm.price"></el-input>
              </el-form-item>
              <el-form-item label="优惠价格：" prop="discount">
                <el-input v-model="addMealForm.discount"></el-input>
              </el-form-item>
              <el-form-item label="最大账号数量：" prop="maxaccount">
                <el-input v-model="addMealForm.maxaccount"></el-input>
              </el-form-item>
              <el-form-item label="最大客服数量：" prop="maxsubaccount">
                <el-input v-model="addMealForm.maxsubaccount"></el-input>
              </el-form-item>
              <el-form-item label="最大收银客服数量：" prop="max_cashier_subaccount">
                <el-input v-model="addMealForm.max_cashier_subaccount"></el-input>
              </el-form-item>
              <el-form-item label="时间限制：" prop="timelimit">
                <el-input v-model="addMealForm.timelimit"></el-input>
              </el-form-item>
              <el-form-item label="容量大小：" prop="capacity">
                <el-input v-model="addMealForm.capacity"></el-input>
              </el-form-item>
              <el-form-item label="设置体验版：" prop="domain">
                <el-radio-group v-model="addMealForm.domain">
                  <el-radio :label="1">是</el-radio>
                  <el-radio :label="0">否</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="套餐权限：" prop="package">
                <el-checkbox-group v-model="packages" @change="getPackage">
                  <el-checkbox v-for="(item, index) in pluginList" :key="index" :label="item.id">{{ item.name }}</el-checkbox>
                </el-checkbox-group>
              </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
              <el-button @click="is_showAddMeal = !1">取 消</el-button>
              <el-button type="primary" @click="comfirmAddMealForm">确 定</el-button>
            </span>
          </el-dialog>
        </el-tab-pane>
        <el-tab-pane label="垂直行业" name="2">
          <el-button type="primary" size="small" @click="showAddCase">添加行业</el-button>
          <el-form class="el-form-search" label-width="90px">
            <el-form-item label="行业名称：">
              <el-input v-model="caseName" placeholder="请输入行业名称" size="small"></el-input>
            </el-form-item>
            <el-form-item label="状态：">
              <el-select v-model="is_online" placeholder="请选择" size="small">
                <el-option label="全部" value="">全部</el-option>
                <el-option label="即将上线" :value="0"></el-option>
                <el-option label="已经上线" :value="1"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label=" " label-width="20px">
              <el-button type="primary" size="small" @click="searchCase">搜索</el-button>
              <el-button plain size="small" @click="cancelSearchCase">清空搜索条件</el-button>
            </el-form-item>
          </el-form>
          <el-table :data="caseList" :header-cell-style="{ 'background-color': '#F8F9FA' }" row-key="id">
            <template slot="empty">
              <No />
            </template>
            <el-table-column prop="name" label="行业名称" align="center"></el-table-column>
            <el-table-column prop="describe" label="行业描述" align="center"></el-table-column>
            <el-table-column prop="price" label="背景图" align="center">
              <template v-slot="{ row }">
                <el-image style="width: 100px; height: 50px" :src="row.images"></el-image>
              </template>
            </el-table-column>
            <el-table-column prop="is_online" label="状态" align="center">
              <template v-slot="{ row }">{{ row.is_online == 1 ? '已经上线' : '即将上线' }}</template>
            </el-table-column>
            <el-table-column prop="update_time" label="更新日期" align="center">
              <template v-slot="{ row }">{{ getDateformat(row.update_time) }}</template>
            </el-table-column>
            <el-table-column prop="money" label="操作" align="center">
              <template v-slot="{ row }">
                <el-button @click="editCase(row)" size="small" type="text">编辑</el-button>
                <el-button @click="delCase(row)" size="small" type="text">删除</el-button>
              </template>
            </el-table-column>
          </el-table>
          <el-dialog title="添加/编辑行业" :visible.sync="is_showAddCase" width="600px">
            <el-form ref="form" :model="addCaseForm" :rules="rules1" label-width="100px">
              <el-form-item label="行业名称：" prop="name">
                <el-input v-model="addCaseForm.name"></el-input>
              </el-form-item>
              <el-form-item label="行业描述：" prop="describe">
                <el-input v-model="addCaseForm.describe"></el-input>
              </el-form-item>
              <el-form-item label="方案标识：" prop="sign">
                <el-input v-model="addCaseForm.sign"></el-input>
              </el-form-item>
              <el-form-item label="状态：" prop="is_online">
                <el-radio-group v-model="addCaseForm.is_online">
                  <el-radio :label="0">即将上线</el-radio>
                  <el-radio :label="1">已经上线</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="背景图片：" prop="images">
                <add-img :path="addCaseForm.images" :url="1" @getImg="val => (addCaseForm.images = val)"></add-img>
              </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
              <el-button @click="is_showAddCase = !1">取 消</el-button>
              <el-button type="primary" @click="comfirmAddCaseForm">确 定</el-button>
            </span>
          </el-dialog>
        </el-tab-pane>
        <el-tab-pane label="续费套餐" name="3">
          <el-button type="primary" size="small" @click="showAddRenew">添加续费套餐</el-button>
          <el-form class="el-form-search" label-width="90px">
            <el-form-item label="续费名称：">
              <el-input v-model="renewName" placeholder="请输入续费套餐名称" size="small"></el-input>
            </el-form-item>
            <el-form-item label=" " label-width="20px">
              <el-button type="primary" size="small" @click="searchRenew">搜索</el-button>
              <el-button plain size="small" @click="cancelSearchRenew">清空搜索条件</el-button>
            </el-form-item>
          </el-form>
          <el-table :data="renewList" :header-cell-style="{ 'background-color': '#F8F9FA' }" row-key="id">
            <template slot="empty">
              <No />
            </template>
            <el-table-column prop="name" label="续费套餐名称" align="center"></el-table-column>
            <el-table-column prop="year" label="年限" align="center"></el-table-column>
            <el-table-column prop="price" label="续费套餐价格" align="center"></el-table-column>
            <el-table-column prop="create_time" label="创建日期" align="center">
              <template v-slot="{ row }">{{ getDateformat(row.create_time) }}</template>
            </el-table-column>
            <el-table-column prop="money" label="操作" align="center">
              <template v-slot="{ row }">
                <el-button @click="editRenew(row)" size="small" type="text">编辑</el-button>
                <el-button @click="delRenew(row)" size="small" type="text">删除</el-button>
              </template>
            </el-table-column>
          </el-table>
          <Paging :total="total_number1" :page="page1" :pageNum="rows1" @updatePageNum="updateData1"></Paging>
          <el-dialog title="添加/编辑续费套餐" :visible.sync="is_showAddRenew" width="600px">
            <el-form ref="form" :model="addRenewForm" :rules="rules2" label-width="120px">
              <el-form-item label="编辑续费名称：" prop="name">
                <el-input v-model="addRenewForm.name"></el-input>
              </el-form-item>
              <el-form-item label="续费套餐价格：" prop="price">
                <el-input v-model="addRenewForm.price"></el-input>
              </el-form-item>
              <el-form-item label="年限：" prop="year">
                <el-input v-model="addRenewForm.year"></el-input>
              </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
              <el-button @click="is_showAddRenew = !1">取 消</el-button>
              <el-button type="primary" @click="comfirmAddRenewForm">确 定</el-button>
            </span>
          </el-dialog>
        </el-tab-pane>
      </el-tabs>
    </el-main>
  </el-container>
</template>

<script>
import Header from './components/header';
import Paging from '@/components/paging';
import addImg from '@/components/addImg';
import { getDateformat } from '@/util/getDate';
export default {
  components: {
    Header,
    Paging,
    addImg,
  },
  data() {
    return {
      activeName: '1',
      page: 1,
      rows: 10,
      page1: 1,
      rows1: 10,
      name: '',
      caseName: '',
      renewName: '',
      is_online: '',
      case_id: '',
      mealList: [],
      caseList: [],
      renewList: [],
      pluginList: [],
      total_number: 0,
      total_number1: 0,
      is_showAddMeal: !1,
      is_showAddCase: !1,
      is_showAddRenew: !1,
      packages: [],
      addMealForm: {
        case_id: '',
        name: '',
        package: [],
        maxaccount: '',
        maxsubaccount: '',
        max_cashier_subaccount: '',
        timelimit: '',
        discount: '',
        price: '',
        capacity: '',
        domain: 0,
      },
      addCaseForm: {
        name: '',
        describe: '',
        sign: '',
        images: '',
        is_online: 0,
      },
      addRenewForm: {
        name: '',
        year: '',
        price: '',
      },
      rules: {
        case_id: [
          {
            required: true,
            message: '请选择行业',
            trigger: 'blur',
          },
        ],
        name: [
          {
            required: true,
            message: '请输入套餐名称',
            trigger: 'blur',
          },
        ],
        price: [
          {
            required: true,
            message: '请输入套餐价格',
            trigger: 'blur',
          },
        ],
        discount: [
          {
            required: true,
            message: '请输入优惠价格',
            trigger: 'blur',
          },
        ],
        maxaccount: [
          {
            required: true,
            message: '请输入最大账号数量',
            trigger: 'blur',
          },
        ],
        maxsubaccount: [
          {
            required: true,
            message: '请输入最大客服数量',
            trigger: 'blur',
          },
        ],
        max_cashier_subaccount: [
          {
            required: true,
            message: '请输入最大收银客服数量',
            trigger: 'blur',
          },
        ],
        timelimit: [
          {
            required: true,
            message: '请输入时间限制',
            trigger: 'blur',
          },
        ],
        capacity: [
          {
            required: true,
            message: '请输入容量大小',
            trigger: 'blur',
          },
        ],
        package: [
          {
            type: 'array',
            required: true,
            message: '请选择套餐权限',
            trigger: 'blur',
          },
        ],
      },
      rules1: {
        name: [
          {
            required: true,
            message: '请输入行业名称',
            trigger: 'blur',
          },
        ],
        describe: [
          {
            required: true,
            message: '请输入行业名称',
            trigger: 'blur',
          },
        ],
        images: [
          {
            required: true,
            message: '请添加背景图',
            trigger: 'blur',
          },
        ],
        sign: [
          {
            required: true,
            message: '请输入方案标识',
            trigger: 'blur',
          },
        ],
      },
      rules2: {
        name: [
          {
            required: true,
            message: '请输入续费套餐名称',
            trigger: 'blur',
          },
        ],
        year: [
          {
            required: true,
            message: '请输入年限',
            trigger: 'blur',
          },
        ],
        price: [
          {
            required: true,
            message: '请输入续费套餐价格',
            trigger: 'blur',
          },
        ],
      },
    };
  },
  created() {
    this.activeName = this.$route.query.type;
    this.getMealList();
    this.getCaseList();
    this.getPlugin();
    this.getRenewList();
  },
  methods: {
    searchMeal() {
      this.page = 1;
      this.rows = 10;
      this.getMealList();
    },
    cancelSearchMeal() {
      this.name = '';
      this.case_id = '';
      this.page = 1;
      this.rows = 10;
      this.getMealList();
    },
    searchCase() {
      this.getCaseList();
    },
    cancelSearchCase() {
      this.caseName = '';
      this.is_online = '';
      this.getCaseList();
    },
    searchRenew() {
      this.page1 = 1;
      this.rows1 = 10;
      this.getRenewList();
    },
    cancelSearchRenew() {
      this.renewName = '';
      this.page1 = 1;
      this.rows1 = 10;
      this.getRenewList();
    },
    getDateformat(val) {
      return getDateformat(val);
    },
    updateData(val, status) {
      if (status == 0) {
        this.rows = val;
      } else {
        this.page = val;
      }
      this.getMealList();
    },
    updateData1(val, status) {
      if (status == 0) {
        this.rows1 = val;
      } else {
        this.page1 = val;
      }
      this.getRenewList();
    },
    showAddMeal() {
      this.addMealForm = {
        case_id: '',
        name: '',
        package: '',
        maxaccount: '',
        maxsubaccount: '',
        max_cashier_subaccount: '',
        timelimit: '',
        discount: '',
        price: '',
        capacity: '',
        domain: 0,
      };
      this.packages = [];
      this.is_showAddMeal = !0;
    },
    showAddCase() {
      this.addCaseForm = {
        name: '',
        describe: '',
        sign: '',
        images: '',
        is_online: 0,
      };
      this.is_showAddCase = !0;
    },
    showAddRenew() {
      this.addRenewForm = {
        name: '',
        year: '',
        price: '',
      };
      this.is_showAddRenew = !0;
    },
    getMealList() {
      this.$axios
        .post(this.$api.admin.mealList, {
          page: this.page,
          rows: this.rows,
          name: this.name,
          case_id: this.case_id,
        })
        .then(res => {
          if (res.code == 0) {
            this.mealList = res.result.list;
            this.total_number = res.result.total_number;
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    getCaseList() {
      this.$axios.post(this.$api.admin.caseList, { rows: 10000, name: this.caseName, is_online: this.is_online }).then(res => {
        if (res.code == 0) {
          this.caseList = res.result.list;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    getRenewList() {
      this.$axios
        .post(this.$api.admin.renewMealList, {
          page: this.page1,
          rows: this.rows1,
          name: this.renewName,
        })
        .then(res => {
          if (res.code == 0) {
            this.renewList = res.result.list;
            this.total_number1 = res.result.total_number;
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    getPlugin() {
      this.$axios
        .post(this.$api.admin.salesPlugin, {
          rows: 10000,
        })
        .then(res => {
          if (res.code == 0) {
            this.pluginList = res.result.list;
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    getPackage(val) {
      this.addMealForm.package = val;
    },
    delMeal(row) {
      this.$confirm('请确认是否删除？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        this.$axios
          .post(this.$api.admin.delMeal, {
            id: row.id,
          })
          .then(res => {
            if (res.code == 0) {
              this.$message.success('删除成功');
              this.getMealList();
            } else {
              this.$message.error(res.msg);
            }
          });
      });
    },
    delCase(row) {
      this.$confirm('请确认是否删除？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        this.$axios
          .post(this.$api.admin.delCase, {
            id: row.id,
          })
          .then(res => {
            if (res.code == 0) {
              this.$message.success('删除成功');
              this.getCaseList();
            } else {
              this.$message.error(res.msg);
            }
          });
      });
    },
    delRenew(row) {
      this.$confirm('请确认是否删除？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        this.$axios
          .post(this.$api.admin.delRenewMeal, {
            id: row.id,
          })
          .then(res => {
            if (res.code == 0) {
              this.$message.success('删除成功');
              this.getRenewList();
            } else {
              this.$message.error(res.msg);
            }
          });
      });
    },
    editMeal(row) {
      for (let i in this.addMealForm) {
        for (let y in row) {
          if (i == y) {
            this.addMealForm[i] = row[y];
          }
        }
      }
      this.packages = row.packages.map(Number);
      this.addMealForm.package = row.packages.map(Number);
      this.addMealForm.id = row.id;
      this.is_showAddMeal = !0;
    },
    editCase(row) {
      this.addCaseForm.name = row.name;
      this.addCaseForm.describe = row.describe;
      this.addCaseForm.sign = row.sign;
      this.addCaseForm.images = row.images;
      this.addCaseForm.is_online = row.is_online;
      this.addCaseForm.id = row.id;
      this.is_showAddCase = !0;
    },
    editRenew(row) {
      this.addRenewForm.name = row.name;
      this.addRenewForm.year = row.year;
      this.addRenewForm.price = row.price;
      this.addRenewForm.id = row.id;
      this.is_showAddRenew = !0;
    },
    comfirmAddMealForm() {
      this.$refs.form.validate(valid => {
        if (valid) {
          let url = this.addMealForm.id ? this.$api.admin.editMeal : this.$api.admin.addMeal;
          this.$axios.post(url, this.addMealForm).then(res => {
            if (res.code == 0) {
              this.is_showAddMeal = !1;
              this.$message.success(this.addMealForm.id ? '编辑成功' : '添加成功');
              this.getMealList();
            } else {
              this.$message.error(res.msg);
            }
          });
        }
      });
    },
    comfirmAddCaseForm() {
      this.$refs.form.validate(valid => {
        if (valid) {
          let url = this.addCaseForm.id ? this.$api.admin.editCase : this.$api.admin.addCase;
          this.$axios.post(url, this.addCaseForm).then(res => {
            if (res.code == 0) {
              this.is_showAddCase = !1;
              this.$message.success(this.addCaseForm.id ? '编辑成功' : '添加成功');
              this.getCaseList();
            } else {
              this.$message.error(res.msg);
            }
          });
        }
      });
    },
    comfirmAddRenewForm() {
      this.$refs.form.validate(valid => {
        if (valid) {
          let url = this.addRenewForm.id ? this.$api.admin.editRenewMeal : this.$api.admin.addRenewMeal;
          this.$axios.post(url, this.addRenewForm).then(res => {
            if (res.code == 0) {
              this.is_showAddRenew = !1;
              this.$message.success(this.addRenewForm.id ? '编辑成功' : '添加成功');
              this.getRenewList();
            } else {
              this.$message.error(res.msg);
            }
          });
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.el-container {
  flex-direction: column;
  .el-main {
    background: #fff;
    .el-form {
      margin-top: 10px;
    }
  }
}
</style>